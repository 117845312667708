<!-- /* eslint-disable import/no-cycle */ -->
<template>
  <v-container fluid>
    <v-row v-if="hasAuthoritySettingDeploymentsInsuranceCarrierMenu" justify="center" class="mt-3">
      <v-col cols="12" md="4">
        <v-btn color="primary" block outlined @click="redirectManagementConfigurationByCarrier">
          Configuração e implantações Operadora
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="hasAuthorityBatchGenerationMenu" justify="center" class="mt-3">
      <v-col cols="12" md="4">
        <v-btn color="primary" block outlined @click="redirectBatchConfiguration">
          Configuração de geração de lote
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="hasNoAuthorities" align="center" justify="center" class="mt-15">
      <v-col align="center" cols="12">
      <v-row justify="center" class="mt-3">
        <v-col cols="12" md="3" lg="3" align="center">
          <v-icon large color="primary">fas fa-low-vision</v-icon>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-3">
        <v-col cols="12" md="3" lg="3" align="center">
        <p class="text--secondary label">Você não tem permissão para visualizar os menus.</p>
        </v-col>
      </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserUtils from '@/shared/utils/user-utils';

export default {
  data: () => ({
    hasNoAuthorities: false,
    hasAuthorityBatchGenerationMenu: false,
    hasAuthoritySettingDeploymentsInsuranceCarrierMenu: false,
  }),

  created() {
    this.userUtils = new UserUtils();
  },

  async mounted() {
    sessionStorage.removeItem('financialGroupId');
    this.loadAuthoritiesViewMenus();
    this.verifyAuthorities();
  },

  methods: {
    async loadAuthoritiesViewMenus() {
      this.hasAuthorityBatchGenerationMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_configuracao_geracao_lote' });
      this.hasAuthoritySettingDeploymentsInsuranceCarrierMenu = this.userUtils.hasAuthorityToAccess({ authority: 'sdi_configuracao_implantacao_operadora' });
    },
    verifyAuthorities() {
      if (this.hasAuthorityBatchGenerationMenu || this.hasAuthoritySettingDeploymentsInsuranceCarrierMenu) {
        this.hasNoAuthorities = false;
      } else {
        this.hasNoAuthorities = true;
      }
    },
    redirectManagementConfigurationByCarrier() {
      this.$router.push({ name: 'ManagementConfigurationByCarrier' });
    },
    redirectBatchConfiguration() {
      this.$router.push({ name: 'BatchConfiguration' });
    },
  },
};
</script>
